var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "제안 의견" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "제목",
                        name: "boardTitle",
                      },
                      model: {
                        value: _vm.data.boardTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardTitle", $$v)
                        },
                        expression: "data.boardTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "의견게시일",
                        name: "boardDate",
                      },
                      model: {
                        value: _vm.data.boardDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardDate", $$v)
                        },
                        expression: "data.boardDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.data.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "plantCd", $$v)
                        },
                        expression: "data.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        rows: 8,
                        label: "의견",
                        name: "boardContents",
                      },
                      model: {
                        value: _vm.data.boardContents,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardContents", $$v)
                        },
                        expression: "data.boardContents",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfoRequest,
                        editable: _vm.editable,
                        label: "제안의견 첨부파일",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.resultCd,
                  expression: "data.resultCd",
                },
              ],
              staticClass: "cardClassDetailForm",
              attrs: { title: "처리결과", bgClass: "orange-2" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        editable: false,
                        codeGroupCd: "SOP_BOARD_RESULT_CD",
                        itemText: "codeName",
                        itemValue: "code",
                        type: "edit",
                        name: "resultCd",
                        label: "처리결과",
                      },
                      model: {
                        value: _vm.data.resultCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "resultCd", $$v)
                        },
                        expression: "data.resultCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: false,
                        rows: 8,
                        label: "의견 처리 결과",
                        name: "boardResultContent",
                      },
                      model: {
                        value: _vm.data.boardResultContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "boardResultContent", $$v)
                        },
                        expression: "data.boardResultContent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: false,
                        label: "의견결과 첨부파일",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          _c("div", { staticClass: "popup-bottom-bar" }, [
            _c(
              "div",
              { staticClass: "popup-bottom-bar-btngroup" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable &&
                    _vm.popupParam.boardId &&
                    _vm.deletable &&
                    _vm.isOwner
                      ? _c("c-btn", {
                          attrs: {
                            flat: "",
                            label: "삭제",
                            editable: _vm.editable,
                            icon: "delete_forever",
                          },
                          on: { btnClicked: _vm.removeData },
                        })
                      : _vm._e(),
                    (_vm.editable && _vm.popupParam.boardId && _vm.isOwner) ||
                    (_vm.editable && !_vm.popupParam.boardId)
                      ? _c("c-btn", {
                          attrs: {
                            flat: "",
                            url: _vm.saveUrl,
                            isSubmit: _vm.isSave,
                            param: _vm.data,
                            mappingType: _vm.mappingType,
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveData,
                            btnCallback: _vm.saveCallback,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popup-bottom-bar-close" },
              [
                _c("q-btn", {
                  attrs: { flat: "", color: "gray", icon: "arrow_back" },
                  on: { click: _vm.closePopUps },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }